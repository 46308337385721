import React from "react"
import PageHero from "../components/pagehero"
import Footer from "../components/footer"
import BlogSection from "../components/BlogSection"
import MobileHeader from "../components/MobileHeader"

const BlogIndex = ({ data }) => {
  return (
    <div>
      <MobileHeader></MobileHeader>
      <PageHero
        heading={["We are a thinking company, and sometimes we ", <span key="blog">write our thoughts</span>]}
        description="Our blog is where you'll find useful information and tips for your organization"
        imgSrc="/blog_page.jpg"
        pageTitle="Our Blog"></PageHero>
      <div className="container-padding">
        <BlogSection />
      </div>
      <Footer></Footer>
    </div>
  )
}


export default BlogIndex
